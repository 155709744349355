$primary: #eabe21;
$secondary: #f5f5f5;
$tertiary: #333333;
$info: #dddddd;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "tertiary": $tertiary,
  "info": $info,
);

@import "node_modules/bootstrap/scss/bootstrap";

// @import url("https://fonts.googleapis.com/css2?family=Be+Vietnam:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=VT323&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");

// @import "slick-carousel/slick/slick.css";
// @import "slick-carousel/slick/slick-theme.css";

// html {
//   //   font-size: 62.5%; // 1rem = 10px
//   -webkit-user-select: none;
//   /* Safari */
//   -ms-user-select: none;
//   /* IE 10 and IE 11 */
//   user-select: none;
//   /* Standard syntax */
// }

body {
  min-width: 300px;
  // font-size: 16px !important;
  // color: $fontColor;
  overflow-x: hidden !important;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.overflowX-scroll {
  overflow-x: "scroll" !important;
}

.overflowY-scroll {
  overflow-y: "scroll" !important;
}

.cursor-pointer {
  cursor: pointer;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.custom-popover.popover {
  overflow: visible !important;
  width: 180px !important;
  height: 210px !important;
  z-index: 99;
  position: absolute !important;
}

.custom-popover {
  z-index: 9999;
}

.open {
  display: block;
}

body {
  font-family: "Manrope", sans-serif !important;
}

.text-bold {
  font-weight: 600 !important;
}

.manrope {
  font-family: "Manrope" sans-serif !important;
}

.manrope-200 {
  font-family: "Manrope" sans-serif;
  font-weight: 200;
  font-style: normal;
}

.manrope-200-i {
  font-family: "Manrope" sans-serif;
  font-weight: 200;
  font-style: italic;
}

.manrope-300 {
  font-family: "Manrope" sans-serif;
  font-weight: 300;
  font-style: normal;
}

.manrope-300-i {
  font-family: "Manrope" sans-serif;
  font-weight: 300;
  font-style: italic;
}

.manrope-400 {
  font-family: "Manrope" sans-serif;
  font-weight: 400;
  font-style: normal;
}

.manrope-400-i {
  font-family: "Manrope" sans-serif;
  font-weight: 400;
  font-style: italic;
}

.manrope-500 {
  font-family: "Manrope" sans-serif;
  font-weight: 500;
  font-style: normal;
}

.manrope-500-i {
  font-family: "Manrope" sans-serif;
  font-weight: 500;
  font-style: italic;
}

.manrope-600 {
  font-family: "Manrope" sans-serif;
  font-weight: 600;
  font-style: normal;
}

.manrope-600-i {
  font-family: "Manrope" sans-serif;
  font-weight: 600;
  font-style: italic;
}

.manrope-700 {
  font-family: "Manrope" sans-serif;
  font-weight: 700;
  font-style: normal;
}

.manrope-700-i {
  font-family: "Manrope" sans-serif;
  font-weight: 700;
  font-style: italic;
}

.manrope-800 {
  font-family: "Manrope" sans-serif;
  font-weight: 800;
  font-style: normal;
}

.manrope-800-i {
  font-family: "Manrope" sans-serif;
  font-weight: 800;
  font-style: italic;
}

.manrope-900 {
  font-family: "Manrope" sans-serif;
  font-weight: 900;
  font-style: normal;
}

.manrope-900-i {
  font-family: "Manrope" sans-serif;
  font-weight: 900;
  font-style: italic;
}

h1,
.h1 {
  font-size: 40px !important;
}

h2,
.h2 {
  font-size: 24px !important;
}

h3,
.h3 {
  font-size: 20px !important;
}

h4,
.h4 {
  font-size: 18px !important;
}

h5,
.h5 {
  font-size: 16px !important;
}

.font-size32 {
  font-size: 32px;
}

.font-size18 {
  font-size: 18px;
}

.subtitle {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
}

.body-text1 {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.body-text2 {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.25px;
}

.body-text3 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.caption {
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.4px;
}

.overline {
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 1.5px;
}

.small-title {
  font-weight: 500;
  font-size: 10.5px;
}

// focus outline
.outline-none:focus,
.outline-none:active {
  outline: none !important;
  box-shadow: none !important;
}

// focus outline
.outline-text-none:focus,
.outline-text-none:active {
  outline: none !important;
  box-shadow: 4px 4px 10px #111, -5px -5px 10px #555;
}

.rotate-on-hover {
  animation: rotateY 0.8s ease-in-out 1;
}

.rotate-on-hover-negative {
  animation: rotateY-negative 0.8s ease-in-out 1s;
}

.text-white {
  color: #ffffff !important;
}

.text-black {
  color: #000000 !important;
}

.text-gray {
  color: gray !important;
}

@keyframes rotateY {
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(180deg);
  }
}

@keyframes rotateY-negative {
  0% {
    transform: rotateY(180deg);
  }

  100% {
    transform: rotateY(0deg);
  }
}

.rotate-behind {
  transform: rotate3d(0, 1, 0, 90deg);
}

.light-animation {
  animation: shrinkAndBrighten 0.5s linear 1;
}

@keyframes shrinkAndBrighten {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

.bg-primary {
  background-color: $primary !important;
}

.bg-secondary {
  background-color: $secondary !important;
}

.border-primary {
  border-color: $primary !important;
}

.border-tertiary {
  border-color: $tertiary !important;
}

.text-primary {
  color: $primary !important;
}

.cursor-pointer {
  cursor: pointer;
}

.text-shadow {
  text-shadow: -4px -4px 10px #555;
}

.text-decoration-none {
  text-decoration: none !important;
}

.css-mxks5r-Menu {
  border-radius: 20px !important;
}

.text-justify {
  text-align: justify;
}

.line-below-heading {
  color: #999999;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #222;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(73, 72, 72);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(73, 72, 72);
}

.noScrollBar::-webkit-scrollbar {
  scrollbar-width: 4px;
  /* for Chrome, Safari, and Opera */
}

.noScrollBar {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
}

.customNeumo {
  box-shadow: 4px 4px 10px #222, -5px -5px 10px #555;
}

.bs-customNeumo {
  outline: none;
  box-shadow: 4px 4px 10px #222, -5px -5px 10px #555;
}

.bs-customNeumo:hover {
  border: none;
  outline: none;
  box-shadow: 4px 4px 10px #222, -5px -5px 10px #555;
}

.customNeumo-textinput {
  box-shadow: 4px 4px 10px #111, -5px -5px 10px #444;
}

.w-dialog {
  max-width: "100%";
}

.w-contentBox {
  border-radius: 40px;
  --smooth-corners: 20;
  mask-image: paint(smooth-corners);
  -webkit-mask-image: paint(smooth-corners);
  background: #0e0e0f;
}

.counter-ol {
  counter-reset: item;
}

.counter-li {
  display: block;
}

.counter-li:before {
  content: counters(item, ".") " ";
  counter-increment: item;
}

.blog-card {
  box-shadow: 0px 0 0px rgba(0, 0, 0, 0.1), 0px 0 0px rgba(0, 0, 0, 0.1),
    0 0px 10px rgba(0, 0, 0, 0.1);
}

.sitemap-link {
  color: #51c0ff;
}

.bg-faded {
  background: rgba(255, 255, 255, 0.027);
  backdrop-filter: blur(2px);
  border-radius: 0px;
}

.modal-width {
  min-width: 90%;
  min-height: 100%;
}

.home-heading {
  font-size: 36px;
}

.custom-header {
  font-size: 32px !important;
  font-weight: 700;
}

h2,
.h2 {
  font-size: 36px !important;
}

.page-header {
  font-family: "Manrope" sans-serif;
  font-size: 36px !important;
  font-weight: 600;
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

  h2,
  .h2 {
    font-size: 40px !important;
  }

  .home-heading {
    font-size: 48px;
  }

  .custom-header {
    font-size: 40px !important;
    font-weight: 700;
  }

  .page-header {
    font-size: 40px !important;
    font-weight: 500;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

  h2,
  .h2 {
    font-size: 48px !important;
  }

  .home-heading {
    font-size: 48px;
  }

  .custom-header {
    font-size: 48px !important;
    font-weight: 700;
  }

  .page-header {
    font-size: 40px !important;
    font-weight: 500;
  }
}

@media (max-width: 992px) {
  .background-video-container {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
  }

  .background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    height: auto;
    transform: translate(-50%, -50%);
    z-index: -1;
    background-size: cover;
    background-position: center;
    transition: 1s opacity;
    border: none;
    outline: none;
    overflow: hidden;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

  h2,
  .h2 {
    font-size: 54px !important;
  }

  .home-heading {
    font-size: 60px;
  }

  .custom-header {
    font-size: 56px !important;
    font-weight: 700;
  }

  .page-header {
    font-size: 48px !important;
    font-weight: 500;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

  h1,
  .h1 {
    font-size: 96px !important;
  }

  h2,
  .h2 {
    font-size: 64px !important;
  }

  .custom-header {
    font-size: 56px !important;
    font-weight: 700;
  }

  .page-header {
    font-size: 48px !important;
    font-weight: 500;
  }

  h3 {
    font-size: 40px !important;
  }

  .font-size32 {
    font-size: 32px;
  }

  h4,
  .h4 {
    font-size: 24px !important;
  }

  h5,
  .h5 {
    font-size: 20px !important;
  }

  .subtitle {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.15px;
  }

  .subtitle-400 {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.15px;
  }

  .subtitle-500 {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.15px;
  }

  .body-text1 {
    font-family: "Manrope" sans-serif !important;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
  }

  .body-text2 {
    font-family: "Manrope" sans-serif !important;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.25px;
  }

  .caption {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.4px;
  }

  .overline {
    font-weight: 400;
    font-size: 10px;
    letter-spacing: 1.5px;
  }

  .small-title {
    font-weight: 500;
    font-size: 10.5px;
  }

  .w-dialog {
    width: 1100px;
  }

  .home-heading {
    font-size: 60px;
  }

  // .home-background {
  //   background-image: url("../src/assets/home/home-hero-section.png");
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   background-size: cover;
  // }

  .background-video-container {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
  }

  .background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translate(-50%, -50%);
    z-index: -1;
    background-size: cover;
    background-position: center;
    transition: 1s opacity;
    border: none;
    outline: none;
  }
}

/* // Extra extral large devices (large desktops, 1200px and up) */
@media (min-width: 1400px) {

  h2,
  .h2 {
    font-size: 64px !important;
  }

  .home-heading {
    font-size: 60px;
  }

  // .home-background {
  //   background-image: url("../src/assets/home/home-hero-section.png");
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   background-size: cover;
  // }
}

.my-select:hover::after {
  transform: translateY(-50%) rotate(-135deg);
}

.my-select::-webkit-select-placeholder {
  /* For Chrome/Opera/Safari */
  color: #fff;
}

.my-select::-moz-placeholder {
  /* For Firefox 19+ */
  color: #fff;
}

.my-select:-ms-input-placeholder {
  /* For IE 10+ */
  color: #fff;
}

.my-select:-moz-placeholder {
  /* For Firefox 18- */
  color: #fff;
}

.my-select option {
  background-color: #0c0d0c;
  color: #fff;
}

.custom-form-control,
.custom-form-control:hover,
.custom-form-control:focus,
.custom-form-control:active {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.modal-open {
  overflow: hidden;
}

.modal-container {
  overflow-y: auto;
}

.custom-modal .custom-modal:modal-content {
  border: none;
  border-radius: none;
}

.custom-modal-dialog {
  padding: 0px !important;
  margin: 0px !important;
  border: none !important;
}

.my-select {
  -webkit-appearance: none;
  appearance: none;
  background-color: #141414;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='5' viewBox='0 0 8 5'%3E%3Cpath fill='%23ffffff' d='M4 5L0 1h8z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #3d3d3d;
  border-radius: 10px;
  padding: 0.5rem 2rem 0.5rem 1rem;
  color: white;
}

.my-select::-ms-expand {
  color: white;
  font-size: 10rem;
  line-height: 10px;
  padding: 0;
  margin-left: 5px;
}

.my-select:focus {
  border-color: #3d3d3d !important;
}

.my-forms {
  border-color: #3d3d3d !important;
  border: 1px solid #3d3d3d !important;
  border-radius: 10px !important;
  background-color: #0d0d0d !important;
}

.my-forms:focus {
  border-color: #3d3d3d !important;
  border: 1px solid #3d3d3d !important;
  border-radius: 10px !important;
  background-color: #0d0d0d !important;
}

.my-forms:hover {
  border-color: #3d3d3d !important;
  border: 1px solid #3d3d3d !important;
  border-radius: 10px !important;
  background-color: #0d0d0d !important;
}

.custom-dots-list {
  height: 0px !important;
}

.slick-dots li {
  height: 8px !important;
}

.card-action-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.option-styles {
  margin-top: 50px !important;
  // color: #fff;
  padding: 8px !important;
  border-radius: 15px !important;
}

.main-nav-links:hover {
  text-decoration: none;
}

.border-underline {
  height: 1px;
  width: 100%;
  border: 1px solid #2e3192 !important;
  border-radius: 100px !important;
}

.border-underline-white {
  height: 1px;
  width: 100%;
  border: 1px solid #ffffff !important;
  border-radius: 100px !important;
}

.border-underline-black {
  height: 1px;
  width: 100%;
  border: 1px solid #000000 !important;
  border-radius: 100px !important;
}

.solutions-container {
  width: 610px !important;
  border: 2px solid #bebebe;
}

.home-background {
  background-color: #eff0fa;
}

.solution-top-background {
  background-color: #eff0fa;
  // background-image: url("../src/assets/services/random/service-top-background.png");
  // background-repeat: no-repeat;
  // background-position: bottom;
  // background-size: cover;
}

.contact-us-home-background {
  background-color: #0b0b0b;
  background-image: url("../src/assets/home/contact-us-home-background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: grid;
  place-items: center;
}

.about-us-hero-background {
  background-color: #0a0a1f;
  background-image: url("../src/assets/home/contact-us-home-background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: grid;
  place-items: center;
}

.footer-headers {
  font-weight: 600;
  font-size: 16px;
}

.footer-info {
  font-weight: 400;
  font-size: 14px;
}

.footer-link {
  font-family: "Manrope";
  cursor: pointer;
  text-decoration: none;
  color: #000000;
}

.card-shadow {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.file-input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.sticky {
  position: sticky;
  top: 110px;
}

@keyframes zoomIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }

  50% {
    transform: scale(1.2);
    opacity: 1;
  }

  100% {
    transform: scale(1);
  }
}

.animated-gemtech-logo {
  animation: zoomIn 1s ease-in-out 1.5s forwards;
  transition: transform 0.3s ease-in-out;
}

@keyframes bounceIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }

  50% {
    transform: scale(1.2);
    opacity: 1;
  }

  100% {
    transform: scale(1);
  }
}

.bouncy-image {
  animation: bounceIn 1s ease-in-out;
}

// .about-us {
//   max-width: 600px;
//   max-height: 600px;
//   background-image: url("../src/assets/home/medicines-in-palm.webp");
//   background-repeat: no-repeat;
//   background-position: center;
//   background-size: cover;
// }

.about-us {
  max-width: 600px;
  max-height: 600px;
  position: relative;
  padding: 5rem;
  border-radius: 20px;
  overflow: hidden;
}

.about-us::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../src/assets/home/medicines-in-palm.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  filter: blur(5px);
  z-index: 1;
}

.about-us .content {
  position: relative;
  z-index: 2;
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

.about-us img {
  animation: bounce 2s infinite;
}

.solution-card {
  transition: transform 0.3s ease;
  overflow: hidden;
}

.solution-card:hover {
  transform: scale(1.1);
}

.enlarged-solution-card {
  transform: scale(1.1);
  overflow: hidden;
}

.feature-point:hover {
  transform: scale(1.05);
}

.inidividual-bullet-point-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  /* background-color: #fff4e0; */
  background-color: #ffebb1;
  border-radius: var(--border-radius);
  transition: box-shadow 200ms ease-in-out;
}

.inidividual-bullet-point-container:hover {
  box-shadow: 1px 1px 14px lightgray;
}

.diamond-card {
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%) !important;
  position: relative;
  background-color: $primary;
  border: 0px 2px 2px 0 solid $primary;
}